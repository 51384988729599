// Store
import {
  setNewRequestPinAddress
} from "../../data/store/Requests";
import { getGoogleMapsAPI } from "./getGoogleMapsApi";

// getProfile
const getGeocode = async (lat,lng) => {
  const googleMaps = await getGoogleMapsAPI();
  const geocoder = new googleMaps.Geocoder();

  try {
    const response = await new Promise((resolve, reject) => {
      geocoder.geocode({ location: { lat, lng } }, (results, status) => {
        if (status === "OK") {
          resolve(results);
        } else {
          reject(new Error(`Geocoding failed: ${status}`));
        }
      });
    });

    setNewRequestPinAddress(response[0].formatted_address);
  } catch (error) {
    console.error("Geocoding error:", error);
    return null;
  }
};

export default getGeocode;
